<template lang="pug">
  .title {{ $t(title) }}
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .title
    margin-top: 20px
    height: 40px
    border-left: 13px solid $default-purple-light
    padding: 10px
    font-weight: 700
    font-size: 16px
</style>
